const config = {
  api: {
    base_url_prod: "https://api-digitizer.mordorintelligence.com/api",
    base_url_dev: 'http://localhost:3001/api',
    // base_url_dev: "https://api-digitizer.mordorlabs.com/api",
    // base_url_dev: "https://api-digitizer.mordorintelligence.com/api",
    base_url_test: "https://api-digitizer.mordorlabs.com/api",
    // base_url_test: 'http://localhost:3001/api',

    mordor_url: "https://www.mordorintelligence.com",
    search: "/api/reports/search?q=",
    edit_reports: "/reports/get-report-list?status=ALL",
    preview_reports: "/reports/get-report-list?status=PUBLISHED",
    report_preview: "/reports/preview-report",
    section_preview: "/reports/preview-section",
    publish_report: "/reports/publish-report",
    unpublish: "/reports/unpublish-report",
    unpublish_factset:"/reports/unpublish-report-factset",
    toc_upload: "/reports/toc-upload",
    pdf_upload: "/reports/upload_pdf_factset",
    add_meta: "/reports/add-meta",
    add_factset_meta: "/reports/add-factset-meta",
    prev_image: "/reports/show-last-upload",
    delete_report: "/reports/delete-report",
    delete_factset_report: "/reports/delete-reports-factset",
    get_report_factset:"/reports/get-report-factset-published",
    export_xml: '/reports/export-xml',
    export_html: "/reports/export-html",
    export_factset: "/reports/export-factset",
    export_factsetv24: "/reports/export-factset-refinitiv",
    mark_as_sent: "/reports/mark-as-sent",
    mark_as_sent_factset : "/reports/mark-as-sent-factset",
    insert_section: "/reports/add-section",
    delete_section: "/reports/delete-section",
    report_list: "/reports/get-report-list",
    factset_catalogue: "/reports/get-report-factset-list",
    report_details: "/reports/get-report-details",
    toc_tree: "/reports/get-toc-tree",
    add_section: "/section/add-content",
    get_section: "/section/get-content",
    section_title: "/reports/update-section-title",
    upload_img: "/reports/upload_img",
    upload_pdf: "/reports/upload_pdf",

    sandp_add_meta: "/reports/add-sandp-meta", // route to upload meta for S&P
    sandp_upload_pdf: "/reports/upload_pdf_sandp", // route to add S&P (file upload)
    sandp_get_reports: "/reports/get-report-sandp-list", // fetch all S&P reports
    sandp_ispublished: "/reports/get-report-sandp-published", // route to get published status for report to show 'isDuplicate warning'
    export_sandp: "/reports/export-sandp", // route to export S&P
    mark_as_sent_sandp: "/reports/mark-as-sent-sandp",
    unpublish_sandp: "/reports/unpublish-report-sandp",
    delete_sandp_report: "/reports/delete-reports-sandp",
    get_report_non_factset_published:"/reports/get-report-non-factset-published"
  },
  security: {
    handsOnTable: "3d74f-b4719-4cb64-24438-8ab1f",
  },
  country_name_with_country_codes :
  [
    {"countryName":"Algeria","countryCode":"DZ"},
    {"countryName":"Angola","countryCode":"AO"},
    {"countryName":"Benin","countryCode":"BJ"},
    {"countryName":"Botswana","countryCode":"BW"},
    {"countryName":"Burkina Faso","countryCode":"BF"},
    {"countryName":"Burundi","countryCode":"BI"},
    {"countryName":"Cameroon","countryCode":"CM"},
    {"countryName":"Cape Verde","countryCode":"CV"},
    {"countryName":"Central African Republic","countryCode":"CF"},
    {"countryName":"Ceuta","countryCode":"EA"},
    {"countryName":"Chad","countryCode":"TD"},
    {"countryName":"Comoros","countryCode":"KM"},
    {"countryName":"Congo","countryCode":"CG"},
    {"countryName":"Cote d'Ivoire","countryCode":"CI"},
    {"countryName":"Democratic Republic of the Congo","countryCode":"CD"},
    {"countryName":"Djibouti","countryCode":"DJ"},
    {"countryName":"Equatorial Guinea","countryCode":"GQ"},
    {"countryName":"Eritrea","countryCode":"ER"},
    {"countryName":"Ethiopia","countryCode":"ET"},
    {"countryName":"Gabon","countryCode":"GA"},
    {"countryName":"Gambia","countryCode":"GM"},
    {"countryName":"Ghana","countryCode":"GH"},
    {"countryName":"Guinea","countryCode":"GN"},
    {"countryName":"Guinea-Bissau","countryCode":"GW"},
    {"countryName":"Kenya","countryCode":"KE"},
    {"countryName":"Liberia","countryCode":"LR"},
    {"countryName":"Libya","countryCode":"LY"},
    {"countryName":"Lsotho","countryCode":"LS"},
    {"countryName":"Madagascar","countryCode":"MG"},
    {"countryName":"Malawi","countryCode":"MW"},
    {"countryName":"Mali","countryCode":"ML"},
    {"countryName":"Maruitius","countryCode":"MU"},
    {"countryName":"Mauritania","countryCode":"MR"},
    {"countryName":"Mayotte","countryCode":"YT"},
    {"countryName":"Melilla","countryCode":"EA"},
    {"countryName":"Morocco","countryCode":"MA"},
    {"countryName":"Mozambique","countryCode":"MZ"},
    {"countryName":"Namibia","countryCode":"NA"},
    {"countryName":"Niger","countryCode":"NE"},
    {"countryName":"Nigeria","countryCode":"NG"},
    {"countryName":"Reunion","countryCode":"RE"},
    {"countryName":"Rwanda","countryCode":"RW"},
    {"countryName":"Saint Helena","countryCode":"SH"},
    {"countryName":"Sao Tome and Principe","countryCode":"ST"},
    {"countryName":"Senegal","countryCode":"SN"},
    {"countryName":"Seychelles","countryCode":"SC"},
    {"countryName":"Sierra Leone","countryCode":"SL"},
    {"countryName":"Somalia","countryCode":"SO"},
    {"countryName":"South Africa","countryCode":"ZA"},
    {"countryName":"Sudan","countryCode":"SD"},
    {"countryName":"Swaziland","countryCode":"SZ"},
    {"countryName":"Tanzania","countryCode":"TZ"},
    {"countryName":"Togo","countryCode":"TG"},
    {"countryName":"Tunisia","countryCode":"TN"},
    {"countryName":"Uganda","countryCode":"UG"},
    {"countryName":"Western Sahara","countryCode":"EH"},
    {"countryName":"Zambia","countryCode":"ZM"},
    {"countryName":"Zimbabwe","countryCode":"ZW"},
    {"countryName":"Anguilla","countryCode":"AI"},
    {"countryName":"Antigua and Barbuda","countryCode":"AG"},
    {"countryName":"Aruba","countryCode":"AW"},
    {"countryName":"Barbados","countryCode":"BB"},
    {"countryName":"Belize","countryCode":"BZ"},
    {"countryName":"Bermuda","countryCode":"BM"},
    {"countryName":"Bolivia","countryCode":"BO"},
    {"countryName":"British Virgin Islands","countryCode":"VG"},
    {"countryName":"Cayman Islands","countryCode":"KY"},
    {"countryName":"Chile","countryCode":"CL"},
    {"countryName":"Ecuador","countryCode":"EC"},
    {"countryName":"Falkland Islands (Malvinas)","countryCode":"FK"},
    {"countryName":"French Guiana","countryCode":"GF"},
    {"countryName":"Greenland","countryCode":"GL"},
    {"countryName":"Guadeloupe","countryCode":"GP"},
    {"countryName":"Guyana","countryCode":"GY"},
    {"countryName":"Martinique","countryCode":"MQ"},
    {"countryName":"Montserrat","countryCode":"MS"},
    {"countryName":"Netherlands Antilles","countryCode":"AN"},
    {"countryName":"Paraguay","countryCode":"PY"},
    {"countryName":"Puerto Rico","countryCode":"PR"},
    {"countryName":"Saint Barthelemy","countryCode":"BL"},
    {"countryName":"Saint Martin","countryCode":"MF"},
    {"countryName":"Saint Pierre and Miquelon","countryCode":"PM"},
    {"countryName":"Saint Vincent and Grenadines","countryCode":"VC"},
    {"countryName":"Suriname","countryCode":"SR"},
    {"countryName":"Turks and Caicos Islands","countryCode":"TC"},
    {"countryName":"United States Virgin Islands","countryCode":"VI"},
    {"countryName":"Uruguay","countryCode":"UY"},
    {"countryName":"United States of America","countryCode":"US"},
    {"countryName":"Laos","countryCode":"LA"},
    {"countryName":"Macau","countryCode":"MO"},
    {"countryName":"Bhutan","countryCode":"BT"},
    {"countryName":"China","countryCode":"CN"},
    {"countryName":"Hong Kong SAR","countryCode":"HK"},
    {"countryName":"India","countryCode":"IN"},
    {"countryName":"Indonesia","countryCode":"ID"},
    {"countryName":"Malaysia","countryCode":"MY"},
    {"countryName":"Maldives","countryCode":"MV"},
    {"countryName":"Nepal","countryCode":"NP"},
    {"countryName":"North Korea","countryCode":"KP"},
    {"countryName":"Philippines","countryCode":"PH"},
    {"countryName":"Singapore","countryCode":"SG"},
    {"countryName":"South Korea","countryCode":"KR"},
    {"countryName":"Sri Lanka","countryCode":"LK"},
    {"countryName":"Taiwan","countryCode":"TW"},
    {"countryName":"Thailand","countryCode":"TH"},
    {"countryName":"Viet Nam","countryCode":"VN"},
    {"countryName":"Bangladesh","countryCode":"BD"},
    {"countryName":"Brunei Darussalam","countryCode":"BN"},
    {"countryName":"Japan","countryCode":"JP"},
    {"countryName":"Kazakhstan","countryCode":"KZ"},
    {"countryName":"Kyrgyzstan","countryCode":"KG"},
    {"countryName":"Mongolia","countryCode":"MN"},
    {"countryName":"Myanmar","countryCode":"MM"},
    {"countryName":"Pakistan","countryCode":"PK"},
    {"countryName":"Tajikistan","countryCode":"TJ"},
    {"countryName":"Timor-Leste","countryCode":"TL"},
    {"countryName":"Turkmenistan","countryCode":"TM"},
    {"countryName":"Uzbekistan","countryCode":"UZ"},
    {"countryName":"Aland Islands","countryCode":"AX"},
    {"countryName":"Albania","countryCode":"AL"},
    {"countryName":"Andorra","countryCode":"AD"},
    {"countryName":"Austria","countryCode":"AT"},
    {"countryName":"Belarus","countryCode":"BY"},
    {"countryName":"Bosnia and Herzegovina","countryCode":"BA"},
    {"countryName":"Bulgaria","countryCode":"BG"},
    {"countryName":"Canary Islands","countryCode":"IC"},
    {"countryName":"Croatia","countryCode":"HR"},
    {"countryName":"Denmark","countryCode":"DK"},
    {"countryName":"Estonia","countryCode":"EE"},
    {"countryName":"Faroe Islands","countryCode":"FO"},
    {"countryName":"Finland","countryCode":"FI"},
    {"countryName":"France","countryCode":"FR"},
    {"countryName":"Gibraltar","countryCode":"GI"},
    {"countryName":"Greece","countryCode":"GR"},
    {"countryName":"Guernsey","countryCode":"GG"},
    {"countryName":"Hungary","countryCode":"HU"},
    {"countryName":"Iceland","countryCode":"IS"},
    {"countryName":"Ireland","countryCode":"IE"},
    {"countryName":"Isle of Man","countryCode":"IM"},
    {"countryName":"Italy","countryCode":"IT"},
    {"countryName":"Jersey","countryCode":"JE"},
    {"countryName":"Latvia","countryCode":"LV"},
    {"countryName":"Liechtenstein","countryCode":"LI"},
    {"countryName":"Lithuania","countryCode":"LT"},
    {"countryName":"Luxembourg","countryCode":"LU"},
    {"countryName":"Malta","countryCode":"MT"},
    {"countryName":"Moldova","countryCode":"MD"},
    {"countryName":"Monaco","countryCode":"MC"},
    {"countryName":"Montenegro","countryCode":"ME"},
    {"countryName":"Netherlands","countryCode":"NL"},
    {"countryName":"Norway","countryCode":"NO"},
    {"countryName":"Poland","countryCode":"PL"},
    {"countryName":"Portugal","countryCode":"PT"},
    {"countryName":"Republic of Macedonia","countryCode":"MK"},
    {"countryName":"Romania","countryCode":"RO"},
    {"countryName":"San Marino","countryCode":"SM"},
    {"countryName":"Serbia","countryCode":"RS"},
    {"countryName":"Slovakia","countryCode":"SK"},
    {"countryName":"Slovenia","countryCode":"SI"},
    {"countryName":"Svalbard and Jan Mayen Islands","countryCode":"SJ"},
    {"countryName":"Sweden","countryCode":"SE"},
    {"countryName":"Switzerland","countryCode":"CH"},
    {"countryName":"Ukraine","countryCode":"UA"},
    {"countryName":"United Kingdom","countryCode":"UK"},
    {"countryName":"Vatican City","countryCode":"VA"},
    {"countryName":"Belgium","countryCode":"BE"},
    {"countryName":"Czech Republic","countryCode":"CZ"},
    {"countryName":"Germany","countryCode":"DE"},
    {"countryName":"Russia","countryCode":"RU"},
    {"countryName":"Spain","countryCode":"ES"},
    {"countryName":"Argentina","countryCode":"AR"},
    {"countryName":"Brazil","countryCode":"BR"},
    {"countryName":"Colombia","countryCode":"CO"},
    {"countryName":"Peru","countryCode":"PE"},
    {"countryName":"Venezuela","countryCode":"VE"},
    {"countryName":"Afghanistan","countryCode":"AF"},
    {"countryName":"Armenia","countryCode":"AM"},
    {"countryName":"Azerbaijan","countryCode":"AZ"},
    {"countryName":"Bahrain","countryCode":"BH"},
    {"countryName":"Cambodia","countryCode":"KH"},
    {"countryName":"Cyprus","countryCode":"CY"},
    {"countryName":"Egypt","countryCode":"EG"},
    {"countryName":"Georgia","countryCode":"GE"},
    {"countryName":"Iran","countryCode":"IR"},
    {"countryName":"Iraq","countryCode":"IQ"},
    {"countryName":"Israel","countryCode":"IL"},
    {"countryName":"Jordan","countryCode":"JO"},
    {"countryName":"Kuwait","countryCode":"KW"},
    {"countryName":"Lebanon","countryCode":"LB"},
    {"countryName":"Oman","countryCode":"OM"},
    {"countryName":"Palestine","countryCode":"PS"},
    {"countryName":"Qatar","countryCode":"QA"},
    {"countryName":"Saudi Arabia","countryCode":"SA"},
    {"countryName":"Syria","countryCode":"SY"},
    {"countryName":"Turkey","countryCode":"TR"},
    {"countryName":"United Arab Emirates","countryCode":"AE"},
    {"countryName":"Yemen","countryCode":"YE"},
    {"countryName":"Bahamas","countryCode":"BS"},
    {"countryName":"Canada","countryCode":"CA"},
    {"countryName":"Costa Rica","countryCode":"CR"},
    {"countryName":"Cuba","countryCode":"CU"},
    {"countryName":"Dominica","countryCode":"DM"},
    {"countryName":"Dominican Republic","countryCode":"DO"},
    {"countryName":"El Salvador","countryCode":"SV"},
    {"countryName":"Grenada","countryCode":"GD"},
    {"countryName":"Guatemala","countryCode":"GT"},
    {"countryName":"Haiti","countryCode":"HT"},
    {"countryName":"Honduras","countryCode":"HN"},
    {"countryName":"Jamaica","countryCode":"JM"},
    {"countryName":"Mexico","countryCode":"MX"},
    {"countryName":"Nicaragua","countryCode":"NI"},
    {"countryName":"Panama","countryCode":"PA"},
    {"countryName":"Saint Kitts and Nevis","countryCode":"KN"},
    {"countryName":"Saint Lucia","countryCode":"LC"},
    {"countryName":"Trinidad and Tobago","countryCode":"TT"},
    {"countryName":"American Samoa","countryCode":"AS"},
    {"countryName":"Australia","countryCode":"AU"},
    {"countryName":"Christmas Island","countryCode":"CX"},
    {"countryName":"Cocos (Keeling) Islands","countryCode":"CC"},
    {"countryName":"Cook Islands","countryCode":"CK"},
    {"countryName":"Fed. States of Micronesia","countryCode":"FM"},
    {"countryName":"Fiji","countryCode":"FJ"},
    {"countryName":"French Polynesia","countryCode":"PF"},
    {"countryName":"Guam","countryCode":"GU"},
    {"countryName":"Johnston Island","countryCode":"JT"},
    {"countryName":"Kiribati","countryCode":"KI"},
    {"countryName":"Marshall Islands","countryCode":"MH"},
    {"countryName":"Micronesia","countryCode":"FM"},
    {"countryName":"Nauru","countryCode":"NR"},
    {"countryName":"New Caledonia","countryCode":"NC"},
    {"countryName":"New Zealand","countryCode":"NZ"},
    {"countryName":"Niue","countryCode":"NU"},
    {"countryName":"Norfolk Island","countryCode":"NF"},
    {"countryName":"Northern Mariana Islands","countryCode":"MP"},
    {"countryName":"Palau","countryCode":"PW"},
    {"countryName":"Papua New Guinea","countryCode":"PG"},
    {"countryName":"Pitcairn","countryCode":"PN"},
    {"countryName":"Samoa","countryCode":"WS"},
    {"countryName":"Solomon Islands","countryCode":"SB"},
    {"countryName":"Tokelau","countryCode":"TK"},
    {"countryName":"Tonga","countryCode":"TO"},
    {"countryName":"Tuvalu","countryCode":"TV"},
    {"countryName":"Vanuatu","countryCode":"VU"},
    {"countryName":"Wallis and Futuna Islands","countryCode":"WF"}
    ],
  personID_with_category_mapping: {
    "AAD": ["Aerospace & Defense", "Automotive"],

    "AFNB": [
      "Agriculture",
      "Food & Beverage",
      "Animal Nutrition & Wellness",
      "Consumer Goods and Services",
    ],

    "E&C": ["Chemicals & Materials", "Energy & Power"],

    "HC": ["Healthcare"],

    "ICT": [
      "Information & Communications Technology",
      "Automation",
      "Electronics",
      "Packaging",
    ],

    "NEO": [
      "Manufacturing Processes",
      "Real Estate and Construction",
      "Financial Services and Investment Intelligence",
      "Logistics",
      "Investment Opportunities",
      "Hospitality & Tourism",
      "Home and Property Improvement",
      "Miscellaneous",
    ],
  },
  personID_value_mapping : {
    "ID111111" : "AAD",
    "ID111112" : "AFNB",
    "ID111113" : "E&C",
    "ID111114" : "HC",
    "ID111115" : "ICT",
    "ID111116" : "NEO",
  },
  geography: ["Global", "Region", "Country"],
  country: [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burma",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo, Democratic Republic",
    "Congo, Republic of the",
    "Costa Rica",
    "Cote d'Ivoire",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Greenland",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea North",
    "Korea South",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Mongolia",
    "Morocco",
    "Monaco",
    "Mozambique",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Norway",
    "Oman",
    "Pakistan",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Samoa",
    "San Marino",
    "Sao Tome",
    "Saudi Arabia",
    "Senegal",
    "Serbia and Montenegro",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ],
  region: [
    "North America",
    "South America",
    "Latin America",
    "Europe",
    "Europe, Middle East, and Africa",
    "Asia Pacific",
    "Australia and New Zealand",
    "Asia",
    "GCC",
    "Africa",
    "West Africa",
    "Middle East",
    "Middle East and Africa",
    "Middle East and North Africa",
    "Sub Saharan Africa",
    "South East Asia",
    "South Asia",
  ],
  global: ["Global"],
  factset_country:[
    'Afghanistan',
    'Aland Islands',
    'Albania',
    'Algeria',
    'American Samoa',
    'Andorra',
    'Angola',
    'Anguilla',
    'Antigua and Barbuda',
    'Argentina',
    'Armenia',
    'Aruba',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bermuda',
    'Bhutan',
    'Bolivia',
    'Bosnia and Herzegovina',
    'Botswana',
    'Brazil',
    'British Virgin Islands',
    'Brunei Darussalam',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Canary Islands',
    'Cape Verde',
    'Cayman Islands',
    'Central African Republic',
    'Ceuta',
    'Chad',
    'Chile',
    'China',
    'Christmas Island',
    'Cocos (Keeling) Islands',
    'Colombia',
    'Comoros',
    'Congo',
    'Cook Islands',
    'Costa Rica',
    "Cote d'Ivoire",
    'Croatia',
    'Cuba',
    'Cyprus',
    'Czech Republic',
    'Democratic Republic of the Congo',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Ethiopia',
    'Falkland Islands (Malvinas)',
    'Faroe Islands',
    'Fed. States of Micronesia',
    'Fiji',
    'Finland',
    'France',
    'French Guiana',
    'French Polynesia',
    'Gabon',
    'Gambia',
    'Gaza Strip',
    'Georgia',
    'Germany',
    'Ghana',
    'Gibraltar',
    'Greece',
    'Greenland',
    'Grenada',
    'Guadeloupe',
    'Guam',
    'Guatemala',
    'Guernsey',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Honduras',
    'Hong Kong SAR',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland',
    'Isle of Man',
    'Israel',
    'Italy',
    'Jamaica',
    'Japan',
    'Jersey',
    'Johnston Island',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    'Kuwait',
    'Kyrgyzstan',
    'Laos',
    'Latvia',
    'Lebanon',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Lsotho',
    'Luxembourg',
    'Macau',
    'Madagascar',
    'Madeira Islands',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands',
    'Martinique',
    'Maruitius',
    'Mauritania',
    'Mayotte',
    'Melanesia',
    'Melilla',
    'Mexico',
    'Micronesia',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Montserrat',
    'Morocco',
    'Mozambique',
    'Myanmar',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands',
    'Netherlands Antilles',
    'New Caledonia',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'Niue',
    'Norfolk Island',
    'North Korea',
    'Northern Mariana Islands',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Palestine',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Pitcairn',
    'Poland',
    'Portugal',
    'Puerto Rico',
    'Qatar',
    'Republic of Macedonia',
    'Reunion',
    'Romania',
    'Russia',
    'Rwanda',
    'Saint Barthelemy',
    'Saint Helena',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Saint Martin',
    'Saint Pierre and Miquelon',
    'Saint Vincent and Grenadines',
    'Samoa',
    'San Marino',
    'Sao Tome and Principe',
    'Saudi Arabia',
    'Scotland',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Korea',
    'Spain',
    'Sri Lanka',
    'Sudan',
    'Suriname',
    'Svalbard and Jan Mayen Islands',
    'Swaziland',
    'Sweden',
    'Switzerland',
    'Syria',
    'Taiwan',
    'Tajikistan',
    'Tanzania',
    'Thailand',
    'Timor-Leste',
    'Togo',
    'Tokelau',
    'Tonga',
    'Trinidad and Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Turks and Caicos Islands',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'United States of America',
    'United States Virgin Islands',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Vatican City',
    'Venezuela',
    'Viet Nam',
    'Wallis and Futuna Islands',
    'West Bank',
    'Western Sahara',
    'Yemen',
    'Zambia',
    'Zimbabwe',
  ],
  factset_region : [
    'Middle East',
    'Europe',
    'Africa',
    'Oceana',
    'Americas',
    'LatinAmerica',
    'NorthAmerica',
    'AsiaPacific',
    'Europe,EMEA',
    'AsiaExJapan',
    'Asia',
    'Americas, NorthAmerica',
  ],
  google_client_id:
    "451946253122-v2ungekfsaaiiamvd3ukivc7aldmunqc.apps.googleusercontent.com",
};

// Deciding base_url based on environment variables.
if (process.env.NODE_ENV === "PRODUCTION")
  config.api.base_url = config.api.base_url_prod;
else
  config.api.base_url =
    window.location.href.indexOf("mordorintelligence") !== -1
      ? config.api.base_url_prod
      : window.location.href.indexOf("mordorlabs") !== -1
      ? config.api.base_url_test
      : config.api.base_url_dev;

export default config;